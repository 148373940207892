import React from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { profile } from "../../assets/images";
import { Typewriter } from "../../components";
import common from "../../common.json";
import moment from "moment";
import "../../App.scss";
import "./index.scss";

export default function Home() {
  const { dateOfJoin, githubLink, linkedInLink, resumeSrc } = common || {},
    experience = moment(dateOfJoin, "dd-mm-yyyy").fromNow("years");
  return (
    <Container id="home">
      <Row className="home">
        <Col md={12} lg={6}>
          <div className="header1">Hey 👋,</div>
          <div className="home__title">
            I am
            <strong>
              <Typewriter
                list={[
                  "React JS Developer",
                  "Front End Developer",
                  "MERN Stack Developer...",
                ]}
              />
            </strong>
          </div>
          <div className="home__content">
            As a highly experienced React developer with &nbsp;
            <strong>{experience}</strong>&nbsp; of professional experience, I
            have had the opportunity to work on a variety of projects that have
            allowed me to hone my skills and expand my expertise in front-end
            development.
            <br /> Some of the key points that I have contributed to and that I
            believe showcase my abilities as a front-end developer include:
            <ul className="mt-3">
              <li>
                Proficient in <strong>multitasking</strong> and able to meet
                tight <strong>deadlines</strong>.
              </li>
              <li>
                Self-starter who continually strives to improve{" "}
                <strong>coding standards</strong>, clarify requirements, and
                seek professional development opportunities.
              </li>
              <li>
                Quick to <strong>identify and resolve</strong> the issues.
              </li>
              <li>
                Skilled in <strong>refactoring</strong> UI code as needed.
              </li>
              <li>
                Utilized <strong>Agile</strong> Scrum methodologies to
                effectively develop an application through multiple sprints.
              </li>
            </ul>
          </div>
          <div className="home__cta">
            <a
              href={resumeSrc}
              target="_blank"
              rel="noreferrer"
              className="home__cta-resume"
            >
              <Button>Resume</Button>
            </a>
            <a
              href={githubLink}
              target="_blank"
              rel="noreferrer noopener"
              title="Follow Rajesh Murugesan on GitHub"
            >
              <FaGithub className="home__cta-icons github" size="48" />
            </a>
            <a
              href={linkedInLink}
              target="_blank"
              rel="noreferrer noopener"
              title="Follow Rajesh Murugesan on LinkedIn"
            >
              <FaLinkedin className="home__cta-icons linkedIn" size="48" />
            </a>
          </div>
        </Col>
        <Col md={12} lg={6} className="home__profile-section">
          <img src={profile} alt="profile" />
        </Col>
      </Row>
    </Container>
  );
}
