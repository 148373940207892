import React from "react";
import { Container } from "reactstrap";
import { MdLocationCity, MdCalendarToday } from "react-icons/md";
import "./index.scss";

export default function Index() {
  const employementList = [
    {
      companyName: "Epsilon",
      location: "Bengaluru",
      designation: "Developer 2",
      duration: "Dec 2021 - Present",
      subHeader:
        "As a front end developer in Epsilon, my roles and responsibilities includes:",
      keyPoints: `
      <li>Build <strong>reusable</strong> react components and <strong>responsive</strong> web pages.</li>
      <li>Knowledge of how to use <strong>MobX's</strong> reactive capabilities to automatically update UI components when the application state changes.</li>
      <li>
      Translating designs and wireframes into <strong>high quality code</strong>.
    </li>
    <li>
    <strong>Collaborating</strong> with a cross-functional team that includes designers, backend developers, and QA engineers.
    </li>
    <li>
    Participating in <strong>agile</strong> development processes, including <strong>sprint planning, stand-ups, and demos</strong>.
    </li>
    <li>Mentoring junior developers and <strong>sharing knowledge</strong> with the team.</li>
    `,
    },
    {
      companyName: "BPMLinks Solutions Private Limited",
      location: "Chennai",
      designation: "Software Engineer",
      duration: "Apr 2019 - Dec 2021",
      keyPoints: `
      <li>Experience using <strong>React Hooks</strong> to manage state and use effects in functional components.</li>
      <li>Experience using the <strong>Context API</strong> to manage state and pass data down the component tree without prop drilling.</li>
      <li>
      Built <strong>responsive</strong> web pages using HTML, CSS, and Bootstrap.
    </li>
    <li>
      Created <strong>reusable</strong> components in React / Angular.
    </li>
    <li>
      Integrated third party libraries like <strong>Material UI / Angular Material</strong>.
    </li>
    <li>
    Handled and created <strong>Redux</strong> for data sharing between components.
    </li>`,
    },
    {
      companyName: "360 Ripples Solutions",
      location: "Chennai",
      designation: "Full Stack Developer Intern",
      duration: "Dec 2018 - Mar 2019",
      keyPoints: `<li>
      Designing and developing <strong>web applications</strong> using Java, Angular, MySQL, and Spring.
    </li>
    <li>
      Building scalable and maintainable back-end API servers using Java and Spring.
    </li>
    <li>
      Implementing front-end components using Angular.
    </li>`,
    },
    {
      companyName: "Zoho",
      location: "Chennai",
      designation: "Incubation Trainee",
      duration: "Sep 2018 - Nov 2018",
      keyPoints: `<li>
      Designing and developing software applications using Java.
    </li>
    <li>
    Writing clean, efficient, and maintainable code.
    </li>`,
    },
  ];
  return (
    <Container className="employement" id="employement">
      <div className="header1">Employement</div>
      {employementList.map((item, i) => {
        const { designation, companyName, location, duration, keyPoints } =
          item;
        return (
          <>
            <div key={i} className="employement__list">
              <div className="employement__list-title">
                {designation} at &nbsp;<span>{companyName}</span>
              </div>
              <div className="employement__list-body">
                <div className="employement__list-sub-header">
                  <MdLocationCity className="location-icon" size="16" />
                  <span>{location}</span>
                  &nbsp;&nbsp;&nbsp;
                  <MdCalendarToday className="location-icon" size="16" />
                  <span>{duration}</span>
                </div>
                <div className="employement__list-content">
                  <ul dangerouslySetInnerHTML={{ __html: keyPoints }}></ul>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </Container>
  );
}
