import React, { useEffect, useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Container,
} from "reactstrap";
import common from "../../common.json";
import "./index.scss";

export default function Index() {
  const { fullName, navItems = [] } = common || {};
  const [headerHeight, setHeaderHeight] = useState(0);
  const [scroll, setScroll] = useState(false);
  const [activeNavItem, setActiveNavItem] = useState("home");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const header = document.getElementById("stick-nav-header");
    setHeaderHeight(header.clientHeight);

    const handleAutoScroll = () => {
      // Get the current scroll position
      const scrollPos = window.pageYOffset;
      if (scrollPos > 1) {
        setScroll(true);
      } else {
        setScroll(false);
      }

      // Get all the links in the navbar
      const links = document.querySelectorAll(".navbar-nav a");
      // Loop through the links and check if the scroll position is within the range of each link's corresponding section
      links.forEach((link) => {
        const section = document.querySelector(link.hash);
        if (
          section.offsetTop - headerHeight - 75 <= scrollPos &&
          section.offsetTop - headerHeight - 75 + section.offsetHeight >
            scrollPos
        ) {
          setActiveNavItem(link.hash.replace("#", ""));
        }
      });
    };
    window.addEventListener("scroll", handleAutoScroll);
    return () => {
      window.removeEventListener("scroll", handleAutoScroll);
    };
  }, []); //eslint-disable-line

  const handleScroll = (e, id) => {
    e.preventDefault();
    setActiveNavItem(id);
    const section = document.getElementById(id);
    if (section) {
      const sectionTop = section.offsetTop;
      window.scrollTo({
        top: sectionTop - headerHeight,
        behavior: "smooth",
      });
      if (isOpen) setIsOpen(false);
    }
  };

  const toggle = () => setIsOpen(!isOpen);
  return (
    <Navbar
      className={`nav-header ${scroll ? "shawdow" : ""}`}
      expand="lg"
      sticky="top"
      id="stick-nav-header"
    >
      <Container className="nav-header__container">
        <NavbarToggler onClick={toggle} />
        <NavbarBrand onClick={(e) => handleScroll(e, "home")}>
          {fullName}
        </NavbarBrand>
        <Collapse isOpen={isOpen} navbar className="justify-content-end">
          <Nav navbar>
            {navItems.map((item, i) => {
              const { id, name, href } = item || {};
              return (
                <NavItem key={i}>
                  <NavLink
                    href={href}
                    className={`nav-item ${
                      activeNavItem === id ? "active" : ""
                    }`}
                    onClick={(e) => handleScroll(e, id)}
                  >
                    {name}
                  </NavLink>
                </NavItem>
              );
            })}
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}
