import React from "react";
import { Col, Container, Row } from "reactstrap";
import {
  angularLogo,
  bootstrapLogo,
  cssLogo,
  expressLogo,
  htmlLogo,
  javascriptLogo,
  materialUILogo,
  mongodbLogo,
  nodejsLogo,
  reactLogo,
  reduxLogo,
  sassLogo,
  typescriptLogo,
} from "../../assets/images";
import "./index.scss";
export default function Index() {
  const skillsList = [
    { name: "HTML", src: htmlLogo },
    { name: "CSS", src: cssLogo },
    { name: "SASS", src: sassLogo },
    { name: "Bootstrap", src: bootstrapLogo },
    { name: "JavaScript", src: javascriptLogo },
    { name: "TypeScript", src: typescriptLogo },
    { name: "React JS", src: reactLogo },
    { name: "Angular", src: angularLogo },
    { name: "Node", src: nodejsLogo },
    { name: "Express JS", src: expressLogo },
    { name: "MongoDB", src: mongodbLogo },
    { name: "Material UI", src: materialUILogo },
    { name: "Redux", src: reduxLogo },
  ];
  return (
    <Container id="skills" className="skills">
      <div className="header1">Skills</div>
      <Row>
        <Col md={12} lg={6}>
          <div className="skills__content">
            As a frontend developer, I have strong skills in{" "}
            <strong>HTML, CSS, JavaScript</strong> problem-solving and
            communication skills , as well as the ability to work well as part
            of a team.
            <br />
            In addition to these core skills, I have experience with:
            <ul>
              <li>
                <strong>Responsive web design</strong> : I have the ability to
                create websites that look and function well on a variety of
                devices, including smartphones, tablets, and desktop computers.
              </li>
              <li>
                <strong>CSS preprocessors</strong> : I can write more efficient
                and maintainable <strong>CSS</strong>, such as{" "}
                <strong>Sass or Less</strong>.
              </li>
              <li>
                <strong>Reusability</strong> : I am able to build{" "}
                <strong>reusable</strong> components and front-end libraries for
                future use.
              </li>
              <li>
                <strong>JavaScript's frameworks</strong> : I am experienced in
                building complex and interactive web applications using
                libraries such as <strong>React, Angular</strong>.
              </li>
              <li>
                <strong>Optimization</strong> : I am able to optimize components
                for maximum <strong>performance</strong> across a vast array of
                web-capable devices and browsers.
              </li>
              <li>
                <strong>Refactor</strong> : I have the ability to refactor code
                for improved <strong>maintainability and readability</strong>.
              </li>
            </ul>
          </div>
        </Col>
        <Col md={12} lg={6}>
          <div className="sub-header mt-0">Tech Stack</div>
          <section className="skills__list">
            {skillsList.map((skill, i) => {
              return (
                <div className="skills__list-item" key={i}>
                  <img src={skill.src} alt="" />
                  <h4>{skill.name}</h4>
                </div>
              );
            })}
          </section>
        </Col>
      </Row>
    </Container>
  );
}
