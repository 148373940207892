import React from "react";
import "./index.scss";

export default function Index({ list = [] }) {
  return (
    <div className="typewriter">
      <ul>
        {list.map((item, i) => {
          return (
            <li key={i}>
              <span>{item}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
