import React from "react";
import { Button, Container } from "reactstrap";
import { BsArrowRight } from "react-icons/bs";
import common from "../../common.json";
import "./index.scss";

export default function Index() {
  const { projectsTheme, projectList, githubLink } = common;
  return (
    <Container id="projects" className="projects">
      <div className="header1">Projects</div>
      <div className="projects__content">
        This section showcases my passion for coding and my eagerness to learn
        and experiment with new technologies.These are personal projects that I
        have undertaken in my spare time, to learn new skills and to experiment
        with new technologies. Take a look at my &nbsp;
        <a
          href={githubLink}
          target="_blank"
          rel="noreferrer noopener"
          title="Follow Rajesh Murugesan on GitHub"
        >
          <strong>Github</strong>
        </a>
        &nbsp; profile for more hobby projects that I have worked on.
      </div>
      <section className="projects__list">
        {projectList.map((project, i) => {
          const { title, descrption, demoLink, sourceCode } = project || {};
          return (
            <div
              key={i}
              className="projects__card"
              style={{ ...projectsTheme[i % projectsTheme.length] }}
            >
              <div className="projects__card-title">{title}</div>
              <p className="projects__card-content">{descrption}</p>
              <div className="projects__card-action">
                <a href={sourceCode} target="_blank" rel="noreferrer noopener">
                  <Button className="btn btn-primary projects__card-action-cta">
                    Source
                    <BsArrowRight size="16" />
                  </Button>
                </a>
                <a href={demoLink} target="_blank" rel="noreferrer noopener">
                  <Button className="btn btn-primary projects__card-action-cta">
                    Demo
                    <BsArrowRight size="16" />
                  </Button>
                </a>
              </div>
            </div>
          );
        })}
      </section>
    </Container>
  );
}
