import { Navbar } from "./components";
import {
  Home,
  Employement,
  Skills,
  Projects,
  Contact,
  Footer,
} from "./screens";
import "./styles/main.scss";
import "./App.scss";

function App() {
  return (
    <div>
      <Navbar />
      <Home />
      <Skills />
      <Projects />
      <Employement />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
