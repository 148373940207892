import React from "react";
import "./index.scss";

export default function Index() {
  return (
    <div className="footer">
      <em>Developed by Rajesh Murugesan</em>
    </div>
  );
}
