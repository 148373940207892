import React from "react";
import { Container } from "reactstrap";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import "./index.scss";

export default function Index() {
  return (
    <Container id="contact" className="contact">
      <div className="header1">Contact</div>
      <div className="contact__body">
        <div className="contact__email">
          <strong>
            Email me at : &nbsp;
            <a
              href="mailto:rajeshmurugesan228@gmail.com"
              rel="noreferrer noopener"
            >
              rajeshmurugesan228@gmail.com
            </a>
          </strong>
        </div>
        <div className="contact__sub-header">
          Please feel free to reach out to me, I look forward to hearing from
          you.
        </div>
        <div className="contact__links">
          <a
            href="https://github.com/rajesh9233"
            target="_blank"
            rel="noreferrer noopener"
            title="Follow Rajesh Murugesan on GitHub"
          >
            <FaGithub className="contact__links-icons github" size="36" />
          </a>
          <a
            href="https://www.linkedin.com/in/rajesh-murugesan/"
            target="_blank"
            rel="noreferrer noopener"
            title="Follow Rajesh Murugesan on LinkedIn"
          >
            <FaLinkedin className="contact__links-icons linkedIn" size="36" />
          </a>
        </div>
      </div>
    </Container>
  );
}
